<div id="home" class="app-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>{{Content.title}}</h1>
                    <h2>{{Content.title2}}</h2>
                    <p>{{Content.paragraph}}</p>
                    <div class="banner-holder">
                        <a class="default-btn" href="https://hubspot.bext360.com/bext360-book-a-demo" target="_blank">{{Content.button1}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="banner-image" *ngFor="let Image of bannerImage;">
                    <img [src]="Image.img">
                </div>
            </div>
        </div>
        <div class="logo-container">
            <ng-container *ngFor="let logo of logos">
                <div class="logo-wrapper">
                    <img [src]="logo" alt="Company logo" class="logo">
                </div>
            </ng-container>
        </div>
    </div>
</div>