// app/modules/policies/security-policy/security-policy.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-security-policy',
  template: `
  <div class="container mx-auto px-4 py-28">
      <h1 class="text-3xl font-bold mb-6">Bext360 Security Policy</h1>
      
      <div class="bg-white p-6 rounded-lg shadow-md">
        <p class="mb-4">At Bext360, we prioritize the security and integrity of your data. Our blockchain-based supply chain management platform is built with security at its core. This Security Policy outlines the measures we take to protect your information and ensure the reliability of our services.</p>

        <h2 class="text-2xl font-semibold mb-4">1. Data Protection</h2>
        <p class="mb-4">We employ industry-standard encryption protocols to safeguard your data both in transit and at rest. This includes:</p>
        <ul class="list-disc list-inside mb-4">
          <li>TLS/SSL encryption for all data transmitted between your devices and our servers</li>
          <li>AES-256 encryption for data stored in our databases</li>
          <li>Secure key management practices to protect encryption keys</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">2. Blockchain Security</h2>
        <p class="mb-4">Our use of blockchain technology provides inherent security benefits:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Immutability of records, preventing unauthorized alterations</li>
          <li>Decentralized data storage, reducing single points of failure</li>
          <li>Cryptographic verification of transactions</li>
        </ul>
        <p class="mb-4">We regularly audit our blockchain implementation to ensure its continued security and effectiveness.</p>

        <h2 class="text-2xl font-semibold mb-4">3. Access Controls</h2>
        <p class="mb-4">We implement strict access controls to protect your data:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Multi-factor authentication for all user accounts</li>
          <li>Role-based access control (RBAC) to limit data access to authorized personnel only</li>
          <li>Regular access audits and prompt removal of unnecessary privileges</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">4. Network Security</h2>
        <p class="mb-4">Our network is protected by:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Next-generation firewalls to monitor and control incoming and outgoing network traffic</li>
          <li>Intrusion Detection and Prevention Systems (IDPS) to identify and block potential threats</li>
          <li>Regular vulnerability scans and penetration testing to identify and address potential weaknesses</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">5. Physical Security</h2>
        <p class="mb-4">Our data centers and offices are secured with:</p>
        <ul class="list-disc list-inside mb-4">
          <li>24/7 surveillance and security personnel</li>
          <li>Biometric access controls</li>
          <li>Environmental controls to protect against physical threats</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">6. Employee Security</h2>
        <p class="mb-4">We maintain a culture of security among our employees:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Comprehensive security training for all employees</li>
          <li>Background checks for employees with access to sensitive systems</li>
          <li>Strict policies on device usage and data handling</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">7. Incident Response</h2>
        <p class="mb-4">We have a robust incident response plan in place:</p>
        <ul class="list-disc list-inside mb-4">
          <li>24/7 monitoring for security incidents</li>
          <li>Dedicated incident response team</li>
          <li>Regular drills and updates to our response procedures</li>
          <li>Commitment to prompt notification of affected parties in case of a data breach</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">8. Compliance</h2>
        <p class="mb-4">We adhere to industry standards and regulations, including:</p>
        <ul class="list-disc list-inside mb-4">
          <li>SOC 2 Type II compliance</li>
          <li>GDPR compliance for processing of EU resident data</li>
          <li>Regular third-party security audits</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">9. Continuous Improvement</h2>
        <p class="mb-4">We are committed to continuously improving our security measures:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Regular review and update of security policies and procedures</li>
          <li>Ongoing monitoring of emerging threats and vulnerabilities</li>
          <li>Investment in new security technologies and practices</li>
        </ul>

        <p class="mt-6 mb-4">While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. If you have any questions about the security of our platform, please contact us at security&#64;bext360.com.</p>

        <p class="mt-6 text-sm text-gray-600">Last updated: August 15, 2024</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-top: 8rem; /* Adjust according to header height */
      }
    `
  ]
})
export class SecurityPolicyComponent { }