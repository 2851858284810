import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent: Content[] = [
        {
            title: 'Producer-Powered Transparency',
            title2: 'Building Trust with Primary & Proven Data since 2017',
            paragraph: 'Bext360 provides comprehensive and measurable accountability for global supply chains. Our SaaS platform provides blockchain traceability and sustainability measures, including worker pay, carbon, water, and electricity, from farm to retail.',
            button1: 'Book a Demo',
            button2: 'assets/img/store/2.png'
        }
    ]
    bannerImage: Image[] = [
        {
            img: 'assets/img/app-banner.JPG'
        }
    ]

    logos = [
        'assets/img/Customer_Logos/Altromercato_logo.png',
        'assets/img/Customer_Logos/Ardent_logo.png',
        'assets/img/Customer_Logos/BridgeCity_logo.png',
        'assets/img/Customer_Logos/Catracha_logo.png',
        'assets/img/Customer_Logos/DFEC_logo.png',
        'assets/img/Customer_Logos/Ingredi_logo.png',
        'assets/img/Customer_Logos/Jpalm_logo.png',
        'assets/img/Customer_Logos/OldSoul_logo.png',
        'assets/img/Customer_Logos/PolasCoffee_logo.png',
        'assets/img/Customer_Logos/QC_logo.png',
        'assets/img/Customer_Logos/tocache_logo.png',
        'assets/img/Customer_Logos/esselunga_logo.png',
        'assets/img/Customer_Logos/basf_logo.png',
    ]

}
class Content {
    title : string;
    title2 : string;
    paragraph : string;
    button1 : string;
    button2 : string;
}
class Image {
    img : string;
}