import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-features',
    templateUrl: './app-features.component.html',
    styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Comprehensive Supply Chain Solutions: Proof in Action',
            paragraphText: 'Delivering unparalleled transparency and sustainability across global supply chains through advanced traceability, carbon measurement, and ethical sourcing solutions.'
        }
    ]
    singleAppFeatures: FeaturesContent[] = [
        {
            icon: '../../../../../assets/img/Trace.svg',
            title: 'End-to-End Transparency',
            paragraph: 'Prove the origin, sustainability, and ethical sourcing of your products with blockchain-enabled traceability, providing full visibility from farm to retail and ensuring that regulatory compliance and sustainability claims are substantiated with data-driven insights.'
        },
        {
            icon: '../../../../../assets/img/scope360_blk2.png',
            title: 'Scope360: Carbon MRV Solution',
            paragraph: 'Measure, report, and verify your carbon footprint across Scope 1, 2, and 3 emissions, tracking and validating carbon offset and inset projects, including reforestation and agroforestry initiatives with our advanced Carbon MRV platform.'
        },
        {
            icon: '../../../../../assets/img/SupplyChain.svg',
            title: 'Intelligent Value Chain Mapping',
            paragraph: 'Visualize your entire supply network with detailed, interactive mapping, identifying optimization opportunities, mitigating risks, and gaining actionable insights through comprehensive analytics.'
        },
        {
            icon: '../../../../../assets/img/GrowingPractices.svg',
            title: 'Data-Driven Sustainability',
            paragraph: 'Transform sustainability goals into measurable actions by tracking farm-level data on water usage, electricity consumption, pest control, etc. using tools to set, monitor, and achieve environmental and social targets with robust analytics.'
        },
        {
            icon: '../../../../../assets/img/FairWage.svg',
            title: 'Ethical Sourcing Assurance',
            paragraph: "Ensure compliance with human rights and labor standards using our global transparency tools, strengthening your brand's social impact with verifiable data and demonstrating commitment to ethical practices."
        },
        {
            icon: '../../../../../assets/img/Verification.svg',
            title: 'Regulatory Compliance Solutions',
            paragraph: 'Stay ahead of global regulations like the CSDDD and EU Deforestation Regulation with our advanced geospatial AI, conducting real-time risk assessments and maintaining continuous monitoring to ensure ongoing compliance.'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class FeaturesContent {
    icon : string;
    title : string;
    paragraph : string;
}