<app-app-banner></app-app-banner>

<app-app-features></app-app-features>

<app-app-about></app-app-about>

<!-- <app-app-funfacts></app-app-funfacts> -->
<app-case-studies></app-case-studies>
<app-app-screenshots></app-app-screenshots>
<app-app-pricing></app-app-pricing>



<app-app-team></app-app-team>

<app-app-faq></app-app-faq>

<!-- <app-app-download></app-app-download> -->

<app-app-feedback></app-app-feedback>

<!-- <app-app-newsletter></app-app-newsletter> -->

<app-app-how-it-works></app-app-how-it-works>

<app-app-contact></app-app-contact>