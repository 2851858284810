<div class="copy-right bg-[#fafafa] text-center py-6 sm:py-7 md:py-8">
    <a href="#" class="termly-display-preferences inline-block mb-14">Consent Preferences</a>
   
    <div class="container mx-auto mt-4">
        <div class="w-full max-w-3xl mx-auto mb-4">
            <div class="flex flex-wrap sm:flex-nowrap justify-center">
                <div class="text-gray-800 hover:text-gray-900 px-2">
                    <a href="#/terms-of-service">Terms of Service</a>
                </div>
                <div class="text-gray-800 hover:text-gray-900 px-2">
                    <a href="#/privacy-policy">Privacy Policy</a>
                </div>
                <div class="text-gray-800 hover:text-gray-900 px-2">
                    <a href="#/security-policy">Security Policy</a>
                </div>
                <div class="text-gray-800 hover:text-gray-900 px-2">
                    <a href="#/data-deletion-policy">Data Deletion Policy</a>
                </div>
            </div>
        </div>
        
        <p *ngFor="let Text of copyrightText;">
            <i class="las la-copyright mr-1"></i>{{Text.text}}
        </p>
    </div>
</div>
