// app/modules/policies/terms-of-service/terms-of-service.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  template: `
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-6">Bext 360 Terms of Service</h1>
      
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h2 class="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p class="mb-4">By accessing or using Bext360's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.</p>

        <h2 class="text-2xl font-semibold mb-4">2. Description of Service</h2>
        <p class="mb-4">Bext360 provides a blockchain-based supply chain management platform. We reserve the right to modify, suspend, or discontinue any part of the service at any time.</p>

        <h2 class="text-2xl font-semibold mb-4">3. User Accounts</h2>
        <p class="mb-4">You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>

        <h2 class="text-2xl font-semibold mb-4">4. Privacy and Data Protection</h2>
        <p class="mb-4">Your use of the service is also governed by our Privacy Policy. By using Bext360, you consent to the collection and use of information as detailed in our Privacy Policy.</p>

        <h2 class="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
        <p class="mb-4">The service and its original content, features, and functionality are owned by Bext360 and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>

        <h2 class="text-2xl font-semibold mb-4">6. User Content</h2>
        <p class="mb-4">You retain all rights to any content you submit, post, or display on or through the service. By providing content, you grant us a license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the service.</p>

        <h2 class="text-2xl font-semibold mb-4">7. Termination</h2>
        <p class="mb-4">We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

        <h2 class="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
        <p class="mb-4">In no event shall Bext360, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>

        <h2 class="text-2xl font-semibold mb-4">9. Governing Law</h2>
        <p class="mb-4">These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

        <h2 class="text-2xl font-semibold mb-4">10. Changes to Terms</h2>
        <p class="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms.</p>

        <p class="mt-6 text-sm text-gray-600">Last updated: August 15, 2024</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-top: 8rem; /* Adjust according to header height */
      }
    `
  ]
})
export class TermsOfServiceComponent { }