import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-case-studies',
    template: `
    <section class="case-studies-section">
      <div class="case-studies-container">
        <h2 class="case-studies-title">{{ caseStudiesData.sectionTitle }}</h2>
        <p class="case-studies-description">{{ caseStudiesData.sectionDescription }}</p>
        <div class="case-studies-grid">
          <div *ngFor="let study of caseStudiesData.caseStudies" class="case-study-card">
            <div class="case-study-icon" [ngClass]="study.icon"></div>
            <h3 class="case-study-title">{{ study.title }}</h3>
            <p class="case-study-description">{{ study.description }}</p>
          </div>
        </div>
      </div>
    </section>
  `,
    styleUrls: ['./app-case-studies.component.css']
})
export class CaseStudiesComponent implements OnInit {
    caseStudiesData: any;

    ngOnInit() {
        this.caseStudiesData = {
            "sectionTitle": "Real-World Impact: Our Case Studies",
            "sectionDescription": "Discover how Bext360's solutions have transformed supply chains for sustainability and transparency across various industries:",
            "caseStudies": [
                {
                    "icon": "coffee-beans",
                    "title": "Full Story Coffee: Traceability from Farm to Cup",
                    "description": "Bext360 developed this initiative to build an ecosystem of producers, exporters, importers, and roasters dedicated to enhancing transparency in the coffee supply chain. Starting in Honduras and expanding to Mexico, Peru, El Salvador, Ethiopia, and Nicaragua, the program enables participants to input data directly into the Bext360 app, including harvest details and payment amounts. This data is securely stored on a blockchain, ensuring traceability from farm to cup. Consumers can scan QR codes on coffee bags to view their coffee's journey, fostering trust and supporting sustainable practices. Learn more on the Full Story Coffee website."
                },
                {
                    "icon": "cocoa-pod",
                    "title": "Esselunga: Ensuring Sustainable Cocoa Sourcing",
                    "description": "To help Esselunga achieve 100% sustainable cocoa sourcing for its private label products by 2025, Bext360 partnered with Italy's largest retail trade organization, Federdistribuzione, to deploy blockchain technology for full supply chain traceability. Operating across 11 countries in Latin America and Africa, this initiative ensures compliance with ethical standards and supports smallholder farmers. Bext360's platform captures and secures data on the blockchain, providing transparency from farm to shelf, promoting fair trade practices, and enhancing consumer trust. This partnership sets a new standard for sustainable sourcing in the retail industry, fostering positive social and environmental outcomes."
                },
                {
                    "icon": "cotton-plant",
                    "title": "BASF: Seed 2 Sew Sustainable Cotton",
                    "description": "Bext360 partnered with BASF to launch the 'Seed 2 Sew' initiative, enhancing transparency and accountability in cotton sourcing, an industry often challenged by opaque practices. The platform enables in-depth traceability of Bext360's cotton from seed to garment while tracking carbon emissions, water usage, pesticide application timelines, and electricity consumption. Launched in Greece, the initiative brings together actors within a 250 km radius, minimizing the carbon footprint of production. By fully digitizing the cotton supply chain, Bext360 helps fashion brands substantiate their sustainability claims, setting a new benchmark for integrity and transparency in the industry. Learn more about the initiative on the Seed 2 Sew website."
                }
            ]
        }
    }
}