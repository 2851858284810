import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PreloaderService } from './services/preloader.service';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: Subscription;
    preloaderSubscription: Subscription;

    constructor(
        private router: Router,
        public preloaderService: PreloaderService
    ) { }

    ngOnInit() {
        this.recallJsFuntions();
        this.subscribeToPreloader();
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        if (this.preloaderSubscription) {
            this.preloaderSubscription.unsubscribe();
        }
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.preloaderService.show();
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.preloaderService.hide();
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    subscribeToPreloader() {
        this.preloaderSubscription = this.preloaderService.preloaderState$
            .subscribe(isLoading => {
                if (isLoading) {
                    $('.preloader').fadeIn('slow');
                } else {
                    $('.preloader').fadeOut('slow');
                }
            });
    }
}