import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-feedback',
    templateUrl: './app-feedback.component.html',
    styleUrls: ['./app-feedback.component.scss']
})
export class AppFeedbackComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'User Feedback',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ]
    singleFeedbackItem: Content[] = [
        {
            feedbackText: 'Using this technology platform, we believe that the consumer will be able to access greater traceability for every step along the palm value chain – directly from the farm through to the end consumer.',
            userImg: 'assets/img/team/BASF1.svg',
            userName: 'Marcelo Lu',
            userDesignation: 'Senior Vice President, BASF CARE CHEMICALS North America'
        },
        // {
        //     feedbackText: 'This allows the customer, right there, on demand, to trace their coffee back to the farmer.',
        //     userImg: 'assets/img/team/coda.png',
        //     userName: 'Tommy Thwaites',
        //     userDesignation: 'President of CODA COFFEE'
        // },
        {
            feedbackText: '“Bext360’s system enables us to improve the integrity of our supply chain data and sustainability practices in line with our company’s commitment to strong ESG while complying with GDPR.',
            userImg: 'assets/img/team/Esselunga1.svg',
            userName: 'Frederico Lopes de Mendonça',
            userDesignation: 'Managing Director, ESSELUNGA'
        },
        {
            feedbackText: "With the digitization of premium products and ingredients through Bext360's technology, we are confident that our supply chain ethics align with our mission and commitment to improve sourcing practices for commercial buyers like Whole Foods and consumers investing in sustainable products like ours.",
            userImg: 'assets/img/team/pachasoap.png',
            userName: 'Andy Thornton',
            userDesignation: 'VP of Sourcing and Impact, PACHA SOAP & SOLI'
        },
        {
            feedbackText: "What really attracted me to the project was the ability to have fully transparent coffee documented from seed to cup. We often talk about transparency in the specialty coffee world, but this goes beyond—providing hard data to back our claims of direct trade and create consumer confidence. The impact has been tremendous, notably increasing trust among our customers and making a difference for the producers in Santa Elena which is really spectacular.",
            userImg: 'assets/img/Customer_Logos/QC_logo.png',
            userName: 'Scott Byington',
            userDesignation: 'Roaster, Queen City Coffee, Denver, CO'
        },
        {
            feedbackText: "I love the project mainly because roasters have direct access to information about what happens at origin. You're also able to see how much money producers earn from selling coffee. Traceability is important because it lets you know where the coffee is coming from, and the impact that this project has on the community.",
            userImg: 'assets/img/Customer_Logos/Catracha_logo.png',
            userName: 'Mayra Orellana-Powell',
            userDesignation: 'Producer & Exporter, Catracha Coffee, Santa Elena, Honduras'
        },
        {
            feedbackText: "The benefit of using the Bext360 application is that we're able to trace the coffee, ensuring we can inform our clients about where and when the coffee was harvested. All this information goes into a database that we can share with our clients and anyone else who needs access to these details.",
            userImg: 'assets/img/Customer_Logos/PolasCoffee_logo.png',
            userName: 'Carlos Pola',
            userDesignation: 'Producer, Polas Coffee, Juayúa, El Salvador'
        }
    ]

}
class sectionTitleContent {
    title: string;
    paragraphText: string;
}
class Content {
    feedbackText: string;
    userImg: string;
    userName: string;
    userDesignation: string;
}