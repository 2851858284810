import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-funfacts',
    templateUrl: './app-funfacts.component.html',
    styleUrls: ['./app-funfacts.component.scss']
})
export class AppFunfactsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    funFacts: Content[] = [
        {
            icon: '../../../../../assets/img/Country.svg',
            title: '47',
            subTitle: 'Countries'
        },
        // {
        //     icon: '../../../../../assets/img/Farmer.svg',
        //     title: '13k',
        //     subTitle: 'Producers'
        // },
        {
            icon: '../../../../../assets/img/Product.svg',
            title: '632',
            subTitle: 'Products'
        },
        // {
        //     icon: '../../../../../assets/img/Group.svg',
        //     title: '460k',
        //     subTitle: 'Lots'
        // }
    ]

}
class Content {
    icon : string;
    title : string;
    subTitle : string;
}