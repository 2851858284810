import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';
import { BookLandingComponent } from './components/pages/book-landing/book-landing.component';
import { SaasLandingComponent } from './components/pages/saas-landing/saas-landing.component';
import { ProductsLandingComponent } from './components/pages/products-landing/products-landing.component';
import { PrivacyPolicyComponent } from './components/pages/app-landing/policies/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/app-landing/policies/terms-of-service.component';
import { SecurityPolicyComponent } from './components/pages/app-landing/policies/security-policy.component';
import { DataDeletionPolicyComponent } from './components/pages/app-landing/policies/data-deletion-policy.component';

const routes: Routes = [
    {path: '', component: AppLandingComponent},
    {path: 'book-landing', component: BookLandingComponent},
    {path: 'saas-landing', component: SaasLandingComponent},
    {path: 'products-landing', component: ProductsLandingComponent},
    {path: 'terms-of-service', component: TermsOfServiceComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'security-policy', component: SecurityPolicyComponent},
    {path: 'data-deletion-policy', component: DataDeletionPolicyComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule {}