<app-book-banner></app-book-banner>

<app-book-features></app-book-features>

<app-book-about></app-book-about>

<app-book-funfacts></app-book-funfacts>

<app-book-feedback></app-book-feedback>

<app-book-newsletter></app-book-newsletter>

<app-book-pricing></app-book-pricing>

<app-book-author></app-book-author>

<app-book-contact></app-book-contact>