// app/modules/policies/data-deletion-policy/data-deletion-policy.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-data-deletion-policy',
  template: `
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-6">Bext360 Data Deletion Policy</h1>
      
      <div class="bg-white p-6 rounded-lg shadow-md">
        <p class="mb-4">At Bext360, we respect your right to control your data. This Data Deletion Policy outlines our procedures for handling data deletion requests in the context of our blockchain-based supply chain management platform. We are committed to being transparent about what data can be deleted and what limitations exist due to the nature of blockchain technology.</p>

        <h2 class="text-2xl font-semibold mb-4">1. Types of Data</h2>
        <p class="mb-4">We categorize data into two main types:</p>
        <ul class="list-disc list-inside mb-4">
          <li><strong>Off-chain data:</strong> Information stored in our traditional databases, including personal information, account details, and certain transaction metadata.</li>
          <li><strong>On-chain data:</strong> Information recorded directly on the blockchain, including transaction records and supply chain data points.</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">2. Deletion of Off-chain Data</h2>
        <p class="mb-4">For off-chain data, we offer the following deletion options:</p>
        <ul class="list-disc list-inside mb-4">
          <li><strong>Account Deletion:</strong> Upon request, we will delete your account and associated personal information from our databases.</li>
          <li><strong>Specific Data Deletion:</strong> You can request deletion of specific pieces of information that are not essential for maintaining your account or complying with legal obligations.</li>
          <li><strong>Data Anonymization:</strong> In some cases, we may anonymize your data rather than delete it, particularly if the data is necessary for analytical or historical purposes.</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">3. Handling of On-chain Data</h2>
        <p class="mb-4">Due to the immutable nature of blockchain technology, deletion of on-chain data presents unique challenges:</p>
        <ul class="list-disc list-inside mb-4">
          <li><strong>Immutability:</strong> Data written to the blockchain cannot be deleted or altered. This is a fundamental feature that ensures the integrity and transparency of the supply chain records.</li>
          <li><strong>Pseudonymity:</strong> We design our blockchain records to use pseudonymous identifiers rather than personally identifiable information wherever possible.</li>
          <li><strong>Key Destruction:</strong> While the data itself cannot be removed from the blockchain, we can destroy the keys that link pseudonymous blockchain identifiers to your personal information, effectively rendering the on-chain data anonymous.</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">4. Data Deletion Process</h2>
        <p class="mb-4">To request data deletion:</p>
        <ol class="list-decimal list-inside mb-4">
          <li>Log into your Bext360 account and navigate to the Privacy Settings.</li>
          <li>Select the "Request Data Deletion" option.</li>
          <li>Choose whether you want to delete your entire account or specific data points.</li>
          <li>Confirm your request.</li>
          <li>We will process your request within 30 days and send a confirmation email once completed.</li>
        </ol>

        <h2 class="text-2xl font-semibold mb-4">5. Exceptions and Limitations</h2>
        <p class="mb-4">There are certain limitations to our ability to delete data:</p>
        <ul class="list-disc list-inside mb-4">
          <li>We cannot delete on-chain data, as explained in section 3.</li>
          <li>We may be required to retain certain information to comply with legal obligations, resolve disputes, or enforce our agreements.</li>
          <li>Residual copies of your data may remain in our backup systems for a limited period but will not be available for normal operational use.</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">6. Third-party Data</h2>
        <p class="mb-4">If you have shared data with third parties through our platform, you will need to contact those parties directly to request deletion of that data.</p>

        <h2 class="text-2xl font-semibold mb-4">7. Data Retention Period</h2>
        <p class="mb-4">We retain personal data for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. The specific retention period varies depending on the type of data and its purpose.</p>

        <h2 class="text-2xl font-semibold mb-4">8. Changes to This Policy</h2>
        <p class="mb-4">We may update this Data Deletion Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last updated" date.</p>

        <p class="mt-6 mb-4">If you have any questions about this Data Deletion Policy or wish to request data deletion, please contact us at privacy&#64;bext360.com.</p>

        <p class="mt-6 text-sm text-gray-600">Last updated: August 15, 2024</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-top: 8rem; /* Adjust according to header height */
      }
    `
  ]
})
export class DataDeletionPolicyComponent { }