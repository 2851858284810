import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-team',
    templateUrl: './app-team.component.html',
    styleUrls: ['./app-team.component.scss']
})
export class AppTeamComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Our Leadership Team',
            paragraphText: "Meet the leaders driving Bext360's innovative solutions. With deep expertise in technology, sustainability, and supply chain management, our team is committed to transforming global supply chains."
        }
    ]
    singleTeamBox = [
        {
            img: 'assets/img/team/Dan_Jones_web.jpg',
            title: 'Daniel Jones',
            designation: 'CEO & Founder',
            social: [
                // {
                //     icon: "lab la-facebook-f",
                //     link: "#"
                // },
                // {
                //     icon: "lab la-twitter",
                //     link: "#"
                // },
                {
                    icon: "lab la-linkedin-in",
                    link: "https://www.linkedin.com/in/daniel-jones-376527/"
                },
                // {
                //     icon: "lab la-instagram",
                //     link: "#"
                // }
            ]
        },
        {
            img: 'assets/img/team/Niki_Lewis.jpg',
            title: 'Niki Lewis',
            designation: 'Chief Sustainability Officer',
            social: [
                // {
                //     icon: "lab la-facebook-f",
                //     link: "#"
                // },
                // {
                //     icon: "lab la-twitter",
                //     link: "#"
                // },
                {
                    icon: "lab la-linkedin-in",
                    link: "https://www.linkedin.com/in/niki-lewis/"
                },
                // {
                //     icon: "lab la-instagram",
                //     link: "#"
                // }
            ]
        },
        {
            img: 'assets/img/team/Dean_Headshot_web.jpg',
            title: 'Dean Kingston',
            designation: 'Chief Operating Officer',
            social: [
                // {
                //     icon: "lab la-facebook-f",
                //     link: "#"
                // },
                // {
                //     icon: "lab la-twitter",
                //     link: "#"
                // },
                {
                    icon: "lab la-linkedin-in",
                    link: "https://www.linkedin.com/in/dean-kingston7/"
                },
                // {
                //     icon: "lab la-instagram",
                //     link: "#"
                // }
            ]
        }
    ];

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}