import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-pricing',
    templateUrl: './app-pricing.component.html',
    styleUrls: ['./app-pricing.component.scss']
})
export class AppPricingComponent implements OnInit {

    name = 'Angular';
    singlePricingBox = [
        {
            title: 'Producer Plan',
            price: '$0',
            priceSubText: 'm',
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Origins Mobile App"
                },
                {
                    itemIcon: "las la-check",
                    item: "Data Collection Workflows"
                },
                {
                    itemIcon: "las la-check",
                    item: "Parcel Registration"
                },
                {
                    itemIcon: "las la-check",
                    item: "Polygon Maker"
                },
                {
                    itemIcon: "las la-check",
                    item: "Growing Practice Tracker"
                },
                {
                    itemIcon: "las la-check",
                    item: "EUDR Compliance"
                }
            ],
            buttonText: 'Get Started',
            buttonLink: 'https://hubspot.bext360.com/bext360-book-a-demo​'
        },
        {
            title: 'Standard Plan',
            price: '$925',
            priceSubText: 'm',
            featuresList: [
                
                {
                    itemIcon: "las la-check",
                    item: "Supply Chain Tracker"
                },
                {
                    itemIcon: "las la-check",
                    item: "Asset Manager"
                },
                {
                    itemIcon: "las la-check",
                    item: "Mobile App"
                },
                {
                    itemIcon: "las la-check",
                    item: "Data Collection Workflows"
                },
                {
                    itemIcon: "las la-check",
                    item: "Polygon Maker"
                },
                {
                    itemIcon: "las la-check",
                    item: "Admin Portal"
                },
                {
                    itemIcon: "las la-check",
                    item: "API Access"
                },
                {
                    itemIcon: "las la-check",
                    item: "Customizable Dashboard"
                },
                {
                    itemIcon: "las la-check",
                    item: "B2B and B2C Mock Website"
                },
                {
                    itemIcon: "las la-check",
                    item: "EUDR Compliance"
                }
            ],
            buttonText: 'Get Started',
            buttonLink: 'https://hubspot.bext360.com/bext360-book-a-demo​'
        },
        {
            title: 'Enterprise Plan',
            // price: 'Request Quote',
            priceSubText: 'm',
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Supply Chain Tracker"
                },
                {
                    itemIcon: "las la-check",
                    item: "Asset Manager"
                },
                {
                    itemIcon: "las la-check",
                    item: "Mobile App"
                },
                {
                    itemIcon: "las la-check",
                    item: "Data Collection Workflows"
                },
                {
                    itemIcon: "las la-check",
                    item: "Parcel Registration"
                },
                {
                    itemIcon: "las la-check",
                    item: "Polygon Maker"
                },
                {
                    itemIcon: "las la-check",
                    item: "Admin Portal"
                },
                {
                    itemIcon: "las la-check",
                    item: "API Access"
                },
                {
                    itemIcon: "las la-check",
                    item: "Customizable Dashboard"
                },
                {
                    itemIcon: "las la-check",
                    item: "B2B and B2C Mock Website"
                },
                {
                    itemIcon: "las la-check",
                    item: "EUDR Compliance"
                },
                {
                    itemIcon: "las la-check",
                    item: "Consultation"
                },
                {
                    itemIcon: "las la-check",
                    item: "Project Management"
                },
                {
                    itemIcon: "las la-check",
                    item: "Custom Solutions"
                },
                {
                    itemIcon: "las la-check",
                    item: "White Label Solutions"
                },
                {
                    itemIcon: "las la-check",
                    item: "Marketplace"
                },
                {
                    itemIcon: "las la-check",
                    item: "Scope360"
                }
            ],
            buttonText: 'Get Started',
            buttonLink: 'https://hubspot.bext360.com/bext360-book-a-demo​'
        }
    ];

    constructor() {}

    ngOnInit() {}

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Price & Plans',
            paragraphText: ''
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}