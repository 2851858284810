import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISubmitFormDto } from './contactus.interface';
import { ContactUsDataService } from './contactus.service';
import { PreloaderService } from 'src/app/services/preloader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-app-contact',
    templateUrl: './app-contact.component.html',
    styleUrls: ['./app-contact.component.scss']
})
export class AppContactComponent implements OnInit {
    contactForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private _contactUsDataService: ContactUsDataService, public preloaderService: PreloaderService
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    onSubmit(): void {
        if (this.contactForm.valid) {
            let submitFormDto: ISubmitFormDto = {
                FullName: this.contactForm.value["fullName"],
                Email: this.contactForm.value["email"],
                Phone: this.contactForm.value["phone"],
                Message: this.contactForm.value["message"],
                Password: "zE%#324fbRBZnNZG@*kx0#hzICjh&y1f4JaoO2a7y2MpDlwqL&",
            }
            this.preloaderService.show();
            this._contactUsDataService.postSubmitEmail(submitFormDto).subscribe(res => {
                this.preloaderService.hide();
                this.toastr.success('Thanks for the e-mail.  We will be in touch shortly.');
            })
        } else {
            this.toastr.error('Please fill all the required fields');
            Object.keys(this.contactForm.controls).forEach(key => {
                const control = this.contactForm.get(key);
                control.markAsTouched();
            });
        }
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Get In Touch With Us',
            paragraphText: 'Have a question for us? Are you interested in partnering with us? Have some suggestions or just want to say Hi? Please contact us. We are here to assist you.'
        }
    ];

    contactImage: Image[] = [
        {
            img: 'assets/img/app-contact.png'
        }
    ];
}

interface sectionTitleContent {
    title: string;
    paragraphText: string;
}

interface Image {
    img: string;
}