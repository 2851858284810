<app-saas-banner></app-saas-banner>

<app-saas-partner></app-saas-partner>

<app-saas-features></app-saas-features>

<app-saas-about></app-saas-about>

<app-saas-discover></app-saas-discover>

<app-saas-funfacts></app-saas-funfacts>

<app-saas-newsletter></app-saas-newsletter>

<app-saas-pricing></app-saas-pricing>

<app-saas-team></app-saas-team>

<app-saas-feedback></app-saas-feedback>

<app-saas-app-download></app-saas-app-download>

<app-saas-our-story></app-saas-our-story>

<app-saas-contact></app-saas-contact>