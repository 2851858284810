<section id="pricing" class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singlePricingBox;">
                <div class="single-pricing-box" >
                    <div class="pricing-header">
                        <h3>{{Content.title}}</h3>
                    </div>
                    <div class="price">
                        <ng-container *ngIf="Content.price">
                            {{Content.price}}<span>/{{Content.priceSubText}}</span>
                        </ng-container>
                        <div class="pricing-btn" *ngIf="!Content.price" [ngClass]="{'mtn-3': !Content.price}">
                            <a href="{{Content.buttonLink}}" target="_blank" class="default-btn">Request Quote<span></span></a>
                        </div>
                    </div>
                    <ul class="pricing-features">
                        <li *ngFor="let List of Content.featuresList"><i class="{{List.itemIcon}}"></i> {{List.item}}
                        </li>
                    </ul>
                    <div class="pricing-btn">
                        <a href="{{Content.buttonLink}}" target="_blank" class="default-btn">{{Content.buttonText}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>