// app/modules/policies/privacy-policy/privacy-policy.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  template: `
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-6">Bext360 Privacy Policy</h1>
      
      <div class="bg-white p-6 rounded-lg shadow-md">
        <p class="mb-4">At Bext360, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our blockchain-based supply chain management platform.</p>

        <h2 class="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p class="mb-4">We collect information you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Contact information (e.g., name, email address, phone number)</li>
          <li>Account credentials</li>
          <li>Company information</li>
          <li>Supply chain data</li>
          <li>Transaction information</li>
          <li>Communication data</li>
        </ul>
        <p class="mb-4">We also automatically collect certain information when you use our platform, including:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Log data (e.g., IP address, browser type, pages visited)</li>
          <li>Device information</li>
          <li>Usage data</li>
          <li>Blockchain transaction data</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p class="mb-4">We use your information to:</p>
        <ul class="list-disc list-inside mb-4">
          <li>Provide, maintain, and improve our services</li>
          <li>Process transactions and send related information</li>
          <li>Verify your identity and prevent fraud</li>
          <li>Communicate with you about our services</li>
          <li>Comply with legal obligations</li>
          <li>Monitor and analyze trends and usage</li>
          <li>Enhance the security of our platform</li>
        </ul>

        <h2 class="text-2xl font-semibold mb-4">3. Information Sharing and Disclosure</h2>
        <p class="mb-4">We may share your information in the following circumstances:</p>
        <ul class="list-disc list-inside mb-4">
          <li>With your consent</li>
          <li>With service providers and business partners</li>
          <li>To comply with legal obligations</li>
          <li>In connection with a merger, sale, or asset transfer</li>
          <li>To protect the rights and safety of Bext360 and others</li>
        </ul>
        <p class="mb-4">We do not sell your personal information to third parties.</p>

        <h2 class="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p class="mb-4">We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>

        <h2 class="text-2xl font-semibold mb-4">5. Blockchain Data</h2>
        <p class="mb-4">Please note that data recorded on the blockchain is immutable and public. We take steps to minimize personal data stored on the blockchain, but some transaction data will be publicly visible.</p>

        <h2 class="text-2xl font-semibold mb-4">6. Your Rights and Choices</h2>
        <p class="mb-4">Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, delete, or restrict use of your information. Contact us to exercise these rights.</p>

        <h2 class="text-2xl font-semibold mb-4">7. Changes to This Policy</h2>
        <p class="mb-4">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

        <h2 class="text-2xl font-semibold mb-4">8. Contact Us</h2>
        <p class="mb-4">If you have any questions about this Privacy Policy, please contact us at privacy&#64;bext360.com.</p>

        <p class="mt-6 text-sm text-gray-600">Last updated: [Current Date]</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-top: 8rem; /* Adjust according to header height */
      }
    `
  ]
})
export class PrivacyPolicyComponent { }