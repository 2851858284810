import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-screenshots',
    templateUrl: './app-screenshots.component.html',
    styleUrls: ['./app-screenshots.component.scss']
})
export class AppScreenshotsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Global Partnerships in Action​',
            paragraphText: 'Our expertise in collaborating with farmers and partners globally to enhance transparency and sustainability in supply chains​.'
        }
    ]
    screenshotImage: Image[] = [
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_1.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_2.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_3.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_4.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_5.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_6.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_7.JPG'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_8.jpg'
        },
        {
            img: 'assets/img/Carousel_Images/Carousel_Image_9.jpg'
        },
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}