
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISubmitFormDto } from './contactus.interface';

@Injectable({
  providedIn: 'root'
})

export class ContactUsDataService {

  BASE_URL = "https://bext360apidevelopment.azurewebsites.net/api";

  constructor(private http: HttpClient) { }

  postSubmitEmail(submitFormDto: ISubmitFormDto): Observable<string> {
    return this.http.post<string>(this.BASE_URL + "/email/submitemail", submitFormDto);
  }

}