<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image text-center" *ngFor="let Image of contactImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="name" class="form-control" placeholder="Your Name">
                                    <div *ngIf="contactForm.get('name').touched && contactForm.get('name').invalid" class="alert alert-danger">
                                        Name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" placeholder="Your Email">
                                    <div *ngIf="contactForm.get('email').touched && contactForm.get('email').invalid" class="alert alert-danger">
                                        Please enter a valid email.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="phone" class="form-control" placeholder="Your Phone">
                                    <div *ngIf="contactForm.get('phone').touched && contactForm.get('phone').invalid" class="alert alert-danger">
                                        Phone number is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="subject" class="form-control" placeholder="Your Subject">
                                    <div *ngIf="contactForm.get('subject').touched && contactForm.get('subject').invalid" class="alert alert-danger">
                                        Subject is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" class="form-control" cols="30" rows="6" placeholder="Your Message"></textarea>
                                    <div *ngIf="contactForm.get('message').touched && contactForm.get('message').invalid" class="alert alert-danger">
                                        Message is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button type="submit" class="default-btn">Send Message<span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>