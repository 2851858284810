import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-faq',
    templateUrl: './app-faq.component.html',
    styleUrls: ['./app-faq.component.scss']
})
export class AppFaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Frequently Asked Questions',
            paragraphText: ''
        }
    ]
    accordionItem: Content[] = [
        {
            title: 'What industries and products does Bext360 support?',
            paragraph: "Bext360 is a commodity-agnostic platform specializing in industries focused on sustainable and traceable supply chains for anything that comes from the earth—whether harvested or mined. This includes agriculture (e.g., coffee, cocoa, palm oil), minerals, and textiles (e.g., cotton and hemp). Our platform has traced products from 42 countries, offering comprehensive supply chain solutions. Additionally, we track carbon MRV (Measurement, Reporting, and Verification) for reforestation, agroforestry carbon sequestration, and carbon offsetting and insetting projects.​"
        },
        {
            title: 'What are the main features of the Bext360 platform?',
            paragraph: "The Bext360 platform features origin-to-retail traceability, Scope 3 carbon, water, and electricity calculations, environmental footprint simulation, value chain mapping, and geospatial AI for regulatory compliance. We also provide carbon MRV tracking for reforestation, agroforestry, and carbon offsetting initiatives. These tools offer a comprehensive view of your supply chain's environmental impact."
        },
        {
            title: 'How does Bext360 collect traceability data and integrate with existing systems?',
            paragraph: "Bext360 collects data through multiple methods: our mobile app, SaaS platform, IoT devices, and API integrations. We offer full API integration to connect seamlessly with your existing systems and workflows. This approach ensures efficient, accurate data collection across your supply chain, providing comprehensive traceability while enhancing your current processes."
        },
        {
            title: 'How can Bext360 assist with EUDR compliance?',
            paragraph: "Our platform provides geospatial AI solutions specifically designed for EU Deforestation Regulation compliance. This includes deforestation risk assessment and dynamic risk mitigation strategies. These tools help companies meet EUDR requirements and effectively manage environmental risks associated with their supply chains."
        },
        {
            title: 'Does Bext360 provide certification?',
            paragraph: "Bext360 is not a certifying body. However, we support the data collection process for various sustainability certifications, such as Fair Trade and Rainforest Alliance. Our platform can help streamline the certification process by providing accurate and verifiable data, making it easier for you to achieve and maintain relevant certifications."
        },
        {
            title: 'How do I get started with Bext360?',
            paragraph: "Getting started with Bext360 is a straightforward process. First, contact us to book a demo. Our team will then guide you through the onboarding process, assist in setting up the platform, and provide comprehensive training for your staff to ensure optimal use of our solutions. We're committed to making the integration of our platform as smooth as possible for your organization."
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Content {
    title : string;
    paragraph : string;
}