import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-about',
    templateUrl: './app-about.component.html',
    styleUrls: ['./app-about.component.scss']
})
export class AppAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/Build_Trust.jpg'
        }
    ]
    aboutContent = [
        {
            title: 'Build Trust with Blockchain-Verified Sustainability Data from Origin',
            paragraphList: [
                {
                    paragraph: '',
                },
                
            ],
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Farm to Retail Traceability"
                },
                {
                    itemIcon: "las la-check",
                    item: "Blockchain Agnostic Technology"
                },
                {
                    itemIcon: "las la-check",
                    item: "Full API Integration"
                },
                {
                    itemIcon: "las la-check",
                    item: "Scope3 Carbon, Water & Electricity Calculation"
                },
                {
                    itemIcon: "las la-check",
                    item: "Environmental Footprint Simulation"
                },
                {
                    itemIcon: "las la-check",
                    item: "First Mile Solutions"
                },
                {
                    itemIcon: "las la-check",
                    item: "Complete Value Chain Mapping"
                },
                {
                    itemIcon: "las la-check",
                    item: "Retail Integration & Storyboarding"
                },
                {
                    itemIcon: "las la-check",
                    item: "Compliance Reporting Generation"
                },
                {
                    itemIcon: "las la-check",
                    item: "Report Worker Pay and Conditions"
                }
            ]
        }
    ];

}
class Image {
    img : string;
}